<template>
    <div class="anmentnav darkBack">
        <span>公告：</span>
        <span>{{notice}}</span>
    </div>
</template>

<script>
export default {
    data () {
        return {
            notice:"2021绍兴马拉松开始报名啦！！",
        }
    }
}
</script>

<style scoped>
.anmentnav{
    width:5.94rem;
    height:1.46rem;
    position: relative;
    padding: 0.2rem;
    font-size: 0.16rem;
}
.anmentnav>span:nth-child(1){
    color:#C1C1C1;
}
.anmentnav>span:nth-child(2){
    color:#20D7FE;
}
</style>